<template>
  <div class="Order">
    <div class="orderP">
      <div class="title">Batch Order List</div>
      <div class="operationOrder">
        <el-form
          ref="operationForm"
          :model="operationForm"
          :inline="true"
          label-width="90px"
        >
          <el-form-item label="Task ID" class="form-item" prop="status">
            <el-input
              v-model.trim="operationForm.taskId"
              class="select"
              size="mini"
              placeholder="Please Enter"
              oninput="value=this.value.replace(/\D/g,'')"
              clearable
            />
          </el-form-item>
          <el-form-item label="Creator" class="form-item" prop="creator">
            <el-input
              v-model.trim="operationForm.createPin"
              class="select"
              size="mini"
              placeholder="Please Enter"
              clearable
            />
          </el-form-item>
          <el-form-item label="Status" class="form-item">
            <el-select
              v-model.trim="operationForm.taskState"
              clearable
              size="mini"
              placeholder="Please Enter"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item class="searchButton" style="margin-left: 50px">
            <el-button class="button" @click="onreset">Reset</el-button>
            <el-button
              type="primary"
              class="button"
              icon="el-icon-search"
              @click="onSubmit"
            >
              Search
            </el-button>
          </el-form-item>
          <el-form-item
            label-width="120"
            label="Action Timestamp"
            prop="timeValue"
            class="Action"
          >
            <el-date-picker
              v-model.trim="Timestamp"
              size="mini"
              value-format="yyyy-MM-dd HH:mm:ss"
              ormat="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="-"
              start-placeholder="start date"
              end-placeholder="date closed "
            />
          </el-form-item>
        </el-form>
        <el-upload
          :action="actionUrl"
          :data="datas"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :before-remove="beforeRemove"
          :show-file-list="false"
          multiple
          style="float:left"
        >
          <el-button size="small" type="primary" class="sl-icon-upload">
            Click on the upload
          </el-button>
        </el-upload>
        <div style="float:left;margin-left:16px;margin-bottom:20px">
          <el-button class="button sl-icon-download" @click="downloadTemplate">
            Download VSP template
          </el-button>
        </div>
      </div>

      <!-- 查看日志的table表格 -->
      <div class="panel-body">
        <el-table
          :data="tableData"
          class="tables"
          :header-cell-style="{ background: '#e8f1fe' }"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>

          <el-table-column prop="id" label="Task ID" min-width="180" />
          <el-table-column prop="createTime" label="Create Time" min-width="180"> 
            <template slot-scope="scope">
              <span>{{ scope.row.createTime | filterDate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="creator" label="Creator" min-width="180" />
          <!-- 状态管理 -->
          <el-table-column prop="taskState" label="Task Status" min-width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.taskState === 1"> Ordering </span>

              <span v-if="scope.row.taskState === 4">
                Partial Order Successfully
              </span>

              <span v-if="scope.row.taskState === 2"> Order Failed </span>

              <span v-if="scope.row.taskState === 3"> Order Successfully </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="successCount"
            label="Succeed Number"
            min-width="160"
          />
          <el-table-column prop="failCount" label="Failed Number" />
          <el-table-column
            prop="operate"
            label="Export"
            width="160"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                class="export-button"
                @click="downFile(scope.row)"
              >
                Export
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationDiv">
        <el-pagination
          class="pagination"
          :current-page="pages.currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pages.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBatchOrderList,
  downloadTemplate,
  download
} from '@/api/batchOrder/api';
import dateFormate from '../../utils/date'
export default {
  name: 'Order',
  components: {},
  filters: {
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      actionUrl: process.env.VUE_APP_BASE_API + '/system/task/add',
      datas: { creator: navigator.userAgent },
      // 查询表单数据
      operationForm: {},
      // 事件日期
      tableData: [],
      // 分页数据
      pages: {
        total: 0,
        size: 10,
        currentPage: 1
      },
      // 状态枚举
      options: [
        {
          value: '0',
          label: 'Ordering'
        },
        {
          value: '3',
          label: 'Order Successfully'
        },
        {
          value: '2',
          label: 'Order Failed'
        },
        {
          value: '4',
          label: 'Partial Order Successfully'
        }
      ],
      // 日期是按
      Timestamp: [],
      excel: 'batchOrderTemplate.xlsx'
    };
  },
  computed: {},
  watch: {},
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getBatchOrderLists();
  },
  methods: {
    handlePreview(file) {
      //console.log(file);
    },
    handleRemove(file, fileList) {
      //console.log(file, fileList);
    },
    handleSuccess(res) {
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: 'Operation succeeded'
        });
        this.getList();
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        });
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`Sure to remove ${file.name}？`);
    },
    // 请求接口 获取数据
    getBatchOrderLists() {
      getBatchOrderList(
        Object.assign(
          {
            pageSize: this.pages.size,
            pageNo: this.pages.currentPage
          },
          this.operationForm
        )
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.pages.total  = res.data.totalCount;
        } else {
          this.tableData = [];
          this.pages.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    handleSizeChange(val) {
      this.pages.size = val;
      this.getBatchOrderLists();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getBatchOrderLists();
    },
    downloadTemplate() {
      let fileName = this.excel;
      let bucket = 'xx-vsp-trade';
      download(fileName, bucket).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'VSPtemplate.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    downFile(row) {
      let resultFileUrl = row.resultFileUrl;
      if (row.resultFileUrl) {
        downloadTemplate(resultFileUrl).then(res => {
          var downloadUrl = window.URL.createObjectURL(res);
          var anchor = document.createElement('a');
          anchor.href = downloadUrl;
          anchor.download = row.resultFileUrl;
          anchor.click();
          window.URL.revokeObjectURL(res);
        });
      } else {
        this.$message({
          message: 'The source file is empty',
          type: 'error'
        });
      }
    },

    onreset() {
      this.operationForm = {};
      this.Timestamp = [];
      this.getBatchOrderLists();
    },
    onSubmit() {
      if (this.Timestamp != null) {
        this.operationForm.startCreateTime = this.Timestamp[0];
        this.operationForm.endCreateTime = this.Timestamp[1];
      }
      this.getBatchOrderLists();
    },
    Results() {}
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.Order {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: white;
  // .Action {
  //   /deep/ .el-form-item__label {
  //    line-height: 18px !important;
  //   }
  // }

  ::v-deep .el-form-item__content {
    .el-input__inner {
      height: 32px;
    }
    .el-button{
      width: 100px;
    }
  }

  .title {
    height: 66px;
    border-bottom: 1px solid rgba(183, 188, 201, 0.3);
    padding: 24px 16px;
    font-size: 18px;
    font-weight: Bold;
    line-height: 17px;
  }
  .operationOrder {
    margin: 20px 16px;
  }
  .panel-body {
    padding: 0 16px;
  }
  /deep/.el-button {
    // padding: 0 12px !important;
    font-family: ArialMT;
    font-size: 14px;
  }

  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
  ::v-deep .el-button--text {
    color: #2262ff;
  }

  ::v-deep .el-radio-group {
    .el-radio__label {
      color: #242526;
    }
    .is-checked {
      .el-radio__inner {
        background-color: #2262ff;
      }
      .el-radio__label {
        color: #242526;
      }
    }
  }
  .el-button {
    padding: 0 20px;
  }
  .upload-demo {
    display: inline-block;
  }
  .button {
    height: 32px !important;
    padding: 0 12px;
  }
  .paginationDiv {
    height: 50px;
    .pagination {
      margin-top: 10px;
      margin-right: 10px;
      float: right;
    }
  }
  ::v-deep .el-table {
    .cell {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  ::v-deep .el-pager {
    li {
      border: 0;
    }
  }
}
</style>
<style lang="less">
.Order {
  .form-item:nth-of-type(2) {
    .el-input {
      width: 280px;
    }
  }
}
</style>
